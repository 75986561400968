<template>
	<b-row>
		<b-col v-if="!minimized" cols="12" md="6">
			<PastInformation @minimized="onMinimized" key="decursus-previous-info" />
		</b-col>
		<b-col>
			<CurrentDecursus ref="currentDecursus" @minimized="onMinimized" key="decursus-current" />
		</b-col>
	</b-row>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import PastInformation from "./PastInformation"
import CurrentDecursus from "./CurrentDecursus"

export default {
	name: "decursus",
	components: {
		PastInformation,
		CurrentDecursus
	},
	data() {
		return {
			minimized: false
		}
	},
	methods: {
		onMinimized(minimized) {
			this.minimized = minimized
		}
	}
}
</script>