<template>
    <b-list-group-item class="p-2 mx-1">
        <div class="mb-1">
            <i class="mr-2 fa-solid fa-microscope"></i> <small>{{item?.parent_name}}: {{item?.name}}</small>
        </div>
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form novalidate>
                <validation-provider vid="result_value" v-slot="{ errors }">
                <b-input-group>
                    <b-form-input size="sm" style="width:100px;" :state="errors[0] ? false : null" id="result_value" name="result_value" v-model="form.result_value" placeholder="Set..." @change="passes(onFormSubmit)"/>
                    <b-input-group-append is-text v-if="form.result_value">
                        <b-spinner v-if="$store.getters['loading/isLoading'](`lab-test-${item?.id}`)" small label="Small Spinner"></b-spinner>
                        <i v-else class="fa-solid fa-check text-success"></i>
                    </b-input-group-append>
                </b-input-group>
                </validation-provider>
            </b-form>
        </validation-observer>
    </div>
    </b-list-group-item>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"

export default {
    props: ['item'],
    data() {
        return {
            form: new Form({
                result_value: null,
                consistency_token: null
            })
        }
    },
    computed: {
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        item: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        }
    },
    methods: {
        ...mapActions('lab', ['updateDiagnosticTest']),
        onFormSubmit() {
            this.updateDiagnosticTest({id: this.item.id, data: this.form.data() }).then((updated) => {
                this.form.withData(updated)
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                }
            })
        }
    }
}
</script>