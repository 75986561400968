<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Attests
    </template>
    <attests-base button-top small fixed :callback="createPatientAdminAttest" />
</b-tab>
</template>
<script>
import TabExtension from "../TabExtension"
import AttestsBase from "@/core/components/health/attests/AttestsBase"
import { mapActions } from "vuex"

export default TabExtension.extend({
    name: "attests",
    components: {
        'attests-base': AttestsBase
    },
    methods: {
        ...mapActions('health', ['createPatientAdminAttest']),
    }
})
</script>