import BaseExtension from '@/views/BaseExtension'
export default BaseExtension.extend({
    props: {
        startCollapsed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    watch: {
        startCollapsed: {
            immediate: true,
            handler(val) {
                this.visible = !val
            }
        }
    }
})