<template>
<b-tab lazy title-item-class="mb-1">
	<template slot="title">Medical Cards</template>
	<medical-cards-base :patient="patient" administration small />
</b-tab>
</template>
<script>
import TabExtension from "./TabExtension"
import MedicalCardsBase from "@/core/components/medicalcards/MedicalCardsBase"
export default TabExtension.extend({
	name: "medical-cards",
	components: {
		'medical-cards-base': MedicalCardsBase
	}
})
</script>