<template>
    <b-card class="card-accent-warning" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-hand-dots"></i> <strong>Allergies</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        	<allergies-base borderless :callback="createPatientAllergy"/>
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import AllergiesBase from './AllergiesBase'
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "allergies",
 	components: {
 		'allergies-base': AllergiesBase
    },
    methods: {
        ...mapActions('health', ['createPatientAllergy'])
    }
})
</script>