<template>
	<b-card class="mb-0" header-tag="header" footer-tag="footer" no-body>
	    <template v-slot:header>
	        <i class="fa-regular fa-file-plus"></i> <strong>{{ formHeader }}</strong>
	    </template>
        <template v-if="hasTests">
	    <div class="scrollable-test-list">
		    <b-list-group flush>
				<FormItem v-for="item in filterItems" :test="item" :key="item.id" @remove="onRemoveTest"/>
			</b-list-group>
		</div>
        </template>
        <template v-else>
        	<b-list-group flush>
            	<b-list-group-item class="text-center text-muted">No tests selected</b-list-group-item>
        	</b-list-group>
        </template>
		<b-card-body>
		    <validation-observer ref="observer" v-slot="{ passes }">
		    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
		    	<validation-provider vid="name" v-slot="{ errors }">
                    <b-input-group>
                    <b-form-input id="name" :state="errors[0] ? false : null" v-model="form.name" autocomplete="off" placeholder="Set template name..." />
                    <template #append>
		                <b-overlay
		                    :show="$store.getters['loading/isLoading']('test-template')"
		                    rounded
		                    opacity="0.6"
		                    spinner-small
		                    spinner-variant="success">
	                		<b-button :disabled="!hasTests || $store.getters['loading/isLoading']('test-template')" type="submit" :variant="isExistingTemplate ? 'warning' : 'success'" class="px-4" tabindex="3">{{ isExistingTemplate ? 'Update' : 'Save'}}</b-button>
	            		</b-overlay>
                    	</template>
                    </b-input-group>
                </validation-provider>
            </b-form>
        	</validation-observer>
    	</b-card-body>
	</b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapGetters, mapState, mapActions } from 'vuex'

import FormItem from './FormItem'

export default ComponentExtension.extend({
	name: "lab-template-form",
    props: ['value', 'typeId'],
    components: {
        FormItem
    },
    data() {
        return {
            selected: [],
            items: [],
            form: new Form({
                name: "",
                type_id: this.typeId,
                tests: [],
                consistency_token: null
            })
        }
    },
    computed: {
        ...mapState('lab', {
            activeTemplate: (state) => state.templates.active
        }),
        hasfilteredItems() {
            return this.selected.length > 0
        },
        filterItems() {
            return this.items.filter(item => this.selected.includes(item.id))
        },
        isExistingTemplate() {
            return this.activeTemplate !== null
        },
        hasTests() {
            return this.selected.length > 0
        },
        formHeader() {
            return this.form.name || 'New Template'
        },
    },
    watch: {
        typeId(val) {
            if (val !== this.form.type_id)
                this.form.populate({ type_id: val })
        },
        value(val) {
            this.selected = Array.from(val)
            this.form.populate({ tests: this.selected })
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        activeTemplate: {
            immediate: true,
            handler(val) {
                if(val){
                    this.selected = Array.from(val.tests)
                    this.form.populate(val)
                    this.$emit('input', val.tests)
                    this.$emit("update:typeId", val.type.id);
                    this.$emit('change', val.type.id)
                }else{
                    this.reset()
                }
            }
        },
        hasTests(val) {
            if (!val) {
                this.reset()
                this.setActiveTemplate(null)
            }
        }
    },
    methods: {
        ...mapActions('lab', [
            'filterDiagnosticsTests',
            'createDiagnosticsTemplate', 
            'updateDiagnosticsTemplate', 
            'setActiveTemplate'
        ]),
        onRemoveTest(id) {
            this.selected = this.selected.filter(item => item !== id)
            this.form.populate({ tests: this.selected })
            this.$emit('input', this.selected)
        },
        onFormSubmit() {
            if(this.isExistingTemplate) {
                this.updateDiagnosticsTemplate({id: this.activeTemplate.id, data: this.form.data()}).then(() => {
                    this.reset()
                    this.notify("Diagnostic referral template updated", "Diagnostic referral template was updated successfully")

                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }else{
                this.createDiagnosticsTemplate(this.form.data()).then(() => {
                    this.reset()
                    this.notify("Diagnostic referral template created", "Diagnostic referral template was created successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer?.setErrors(errors.response.data)
                        this.hasErrors = true
                    }
                })
            }
        },
        reset() {
            this.form.reset()
            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.selected = []
                this.validated()
                this.$emit('input', [])
            })
        }
    },
    async created() {
        this.selected = Array.from(this.value)
        this.items = await this.filterDiagnosticsTests()
    }
})
</script>

<style scoped>
.scrollable-test-list {
    overflow-y: scroll;
    max-height: 180px;
}

</style>