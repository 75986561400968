<template>
<b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Disabilities
    </template>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('update_patient')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
        <b-form-group :disabled="isLocked">
            <b-row>
                <b-col v-for="column in Math.ceil(disabilities.length / 10)" :key="`disabilities-column-${column}`">
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                        v-model="form.disability_ids"
                        :options="disabilities.slice((column - 1) * 10, column * 10)"
                        :aria-describedby="ariaDescribedby"
                        text-field="name"
                        value-field="id"
                        stacked
                        switches
                        ></b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form-group>
        </b-overlay>
        <template v-if="checkUserPermission('disability', 'can_change')">
            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
            <b-button v-show="isDirty | hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
        </template>
    </b-form>
    </validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from './TabExtension'

export default TabExtension.extend({
    name: 'disabilities',
    data() {
        return {
            form: new Form({
                disability_ids: [],
                consistency_token: null
            }),
        }
    },
    computed: {
        ...mapState('catalogue', {
            disabilities: state => state.disabilities,
        }),
        ...mapGetters('patients', ['getPatientDemographics']),
        demographics() {
            return this.getPatientDemographics()
        },
        disableIDFields() {
            return this.form.local_foreigner
        },
        enabledDeceasedIndicator() {
            return this.form.deceased_indicator
        }
    },
    watch: {
        demographics: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', [
            'getCatalogueItem',
        ]),
        ...mapActions('patients', ['updatePatient']),
        onFormSubmit() {
            this.updatePatient(this.form.data()).then(demographics => {
                this.reset()
                this.notify('Patient information updated', "Patient information was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        reset() {
            this.form.reset()

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.validated()
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('disabilities')
    }
})
</script>