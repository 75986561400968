<template>
    <b-card class="card-accent-dark" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-prescription-bottle-medical"></i> <strong>Chronic Prescriptions</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
            <prescriptions-base is-chronic borderless :callback="createPatientPrescription" />
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import PrescriptionsBase from './PrescriptionsBase'
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "chronic-prescriptions",
    components: {
        'prescriptions-base': PrescriptionsBase
    },
    methods: {
        ...mapActions('prescriptions', ['createPatientPrescription']),
    }
})
</script>