<template>
    <b-card>
        <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
          <validation-provider vid="allergy_type_id" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Allergy Type"
                label-for="allergy_type_id"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-form-select id="allergy_type_id" name="allergy_type_id" plain v-model="form.allergy_type_id" @change="onAllergyTypeSelected">
                    <b-form-select-option disabled :value="null">* Select Type</b-form-select-option>
                    <b-form-select-option v-for="allergy_type in allergy_types" :key="allergy_type.id" :value="allergy_type.id">{{ allergy_type.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
            </validation-provider>
            <template v-if="typeSelected">
                <validation-provider vid="allergy_code_id" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label-cols-sm="2"
                        label-cols-lg="3"
                        label="Allergy"
                        label-for="allergy_code_id"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <vue-bootstrap-autocomplete id="allergy_code_id" name="allergy_code_id" v-model="query" :data="codes" placeholder="* Search Allergy..." :minMatchingChars="1" :serializer="allergySerializer" @hit="onHitAllergy">
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                                <div class="d-flex align-items-center">
                                    <!-- Note: the v-html binding is used, as htmlText contains
                                    the suggestion text highlighted with <strong> tags -->
                                    <span class="ml-1" v-html="htmlText"></span>
                                </div>
                            </template>
                        </vue-bootstrap-autocomplete>
                    </b-form-group>
                </validation-provider>

                <validation-provider vid="allergy_severity_id" v-slot="{ errors }">
                <b-form-group
                    :label-size="small ? 'sm' : 'md'"
                    label-cols-sm="2"
                    label-cols-lg="3"
                    label="Severity"
                    label-for="allergy_severity_id"
                    :invalid-feedback="errors[0]"
                    :state="errors[0] ? false : null">
                    <b-form-select id="allergy_severity_id" name="allergy_severity_id" plain v-model="form.allergy_severity_id">
                        <b-form-select-option disabled :value="null">* Select Severity</b-form-select-option>
                        <b-form-select-option v-for="severity in allergy_severities" :key="severity.id" :value="severity.id">{{ severity.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                </validation-provider>

                <validation-provider vid="reaction" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label-cols-sm="2"
                        label-cols-lg="3"
                        label="Reaction"
                        label-for="reaction"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-input id="reaction" name="reaction" placeholder="Add allergic reaction..." v-model="form.reaction" />
                    </b-form-group>
                </validation-provider>

                <validation-provider vid="identification_date" v-slot="{ errors }">
                    <b-form-group
                        :label-size="small ? 'sm' : 'md'"
                        label-cols-sm="2"
                        label-cols-lg="3"
                        label="Identification date"
                        label-for="identification_date"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-datepicker id="identification_date" placeholder="Set identification date..." v-model="form.identification_date" :locale="currentLang" :max="maxDate"></b-form-datepicker>

                    </b-form-group>
                </validation-provider>
                <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
            </template>
        </b-form>
        </validation-observer>
    </b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: "allergies-form",
    data() {
        return {
            form: new Form({
                allergy_code_id: null,
                allergy_type_id: null,
                allergy_severity_id: null,
                reaction: "",
                identification_date: null,
                consistency_token: null
            }),
            query: "",

            maxDate: new Date(),

            codes: [],
        }
    },
    computed: {
        ...mapState('catalogue', {
            allergy_codes: state => state.allergy_codes,
            allergy_types: state => state.allergy_types,
            allergy_severities: state => state.allergy_severities
        }),
        typeSelected() {
            return this.form.allergy_type_id !== null
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        onAllergyTypeSelected(id) {
            this.query = ""
            if (id) {
                const type = this.allergy_types.find(type => type.id === id)
                this.codes = this.allergy_codes.filter(code => code.parent === type.code)
            }
        },
        allergySerializer(item) {
            return item.name
        },
        onHitAllergy(item) {
            this.form.populate({allergy_code_id: item.id})
        },
        onFormSubmit() {

            this.callback(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient allergy created", "Patient allergy was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        reset() {
            this.form.reset()

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.query = ""
                this.hasErrors = false
                this.validated()
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('allergy_codes')
        await this.getCatalogueItem('allergy_types')
        await this.getCatalogueItem('allergy_severities')
    }
})
</script>