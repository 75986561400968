<template>
    <b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
            <validation-provider vid="stoppage_reason" v-slot="{ errors }">
                <b-overlay :show="confirm" no-wrap @shown="onShown">
                    <template #overlay>
                        <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                            <p class="text-center">
                                <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">STOP PRESCRIPTION ON {{ toLocaleDate(item?.created) }} </strong>
                            </p>
                            <div class="text-center">
                                <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                                <b-button variant="outline-success" type="submit">Confirm</b-button>
                            </div>
                        </div>
                    </template>
                </b-overlay>

                <b-form-group
                label-cols-sm="2"
                label-cols-lg="3"
                label="Stoppage Reason"
                label-for="stoppage_reason"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                    <b-form-textarea id="stoppage_reason" required v-capitalize v-model="form.stoppage_reason" placeholder="Add reason for stop medication" rows="5"></b-form-textarea>
            </b-form-group>
            </validation-provider>
                        
            <b-button class="mr-1" type="button" variant="success" @click="onStopMedication"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
            </b-form>
    </validation-observer>
    </b-card>
</template>
<script>
import Vue from 'vue'
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import _ from 'lodash'
import printJS from 'print-js'

export default FormExtension.extend({
    name: "prescriptions-stop-form",
    props: ['item'],
    data() {
        return {
            form: new Form({
                stoppage_reason: "",
                consistency_token: null
            }),

            confirm: false,
        }
    },
    watch: {
        item: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
    },
    methods: {
        ...mapActions('prescriptions', ['updatePatientPrescription']),
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
        },
        onStopMedication() {
            this.confirm = true
        },
        onFormSubmit() {
            this.updatePatientPrescription({id: this.item?.id, data: this.form.data() }).then(() => {
                this.reset()
                this.notify("Stop medication", "Patient medication was stopped successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        reset() {
            this.form.reset()

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.validated()
            })
        }
    }
})
</script>
