<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> BloodType
    </template>
    <bloodtype-base :callback="createPatientBloodType" administration />
</b-tab>
</template>
<script>
import TabExtension from "../TabExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import BloodTypeBase from "@/core/components/health/bloodtype/BloodTypeBase"

export default TabExtension.extend({
    name: "blood-type",
    components: {
        'bloodtype-base': BloodTypeBase
    },
    methods: {
        ...mapActions('health', ['createPatientBloodType']),
    }
})
</script>