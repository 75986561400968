<template>
    <b-list-group-item href="#" class="p-0 flex-column align-items-start">
        <div class="ml-2 p-2">
            <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1"><strong>{{ item?.type?.code }}</strong> {{ item?.type?.name }}</h6>
                <b-link class="ml-5" @click="onRemove">
                    <i class="fa-solid fa-trash-can align-middle"></i>
                </b-link>
            </div>

            <small>{{ item?.type?.resource }} - {{ item?.type?.tariff }}</small>
        </div>
        <div>
            <b-form-input id="name" name="name" class="border-left-0 border-right-0 border-bottom-0" v-model="form.description" placeholder="Set description (optional)" @change="save"/>
        </div>
    </b-list-group-item>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import { debounce } from 'lodash'

export default FormExtension.extend({
    name: 'decursus-procedure-list',
    props: ['item'],
    data() {
        return {
            form: new Form({
                description: "",
                type_id: null,
                consistency_token: null
            })
        }
    },
    watch: {
        item: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.form.withData(val)
                }
            }
        }
    },
    methods: {
        ...mapActions('procedures', ['updateDecursusProcedure', 'deleteDecursusProcedure']),
        save() {
            this.updateDecursusProcedure({id: this.item?.id, data: this.form.data()}).then((updated) => {
                this.form.withData(updated)
            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                }
            })
        },
        onRemove() {
            this.deleteDecursusProcedure(this.item?.id).then(() => {
                this.reset()
            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                }
            })
        },
        reset() {
            this.form.reset()
        }
    }
})
</script>