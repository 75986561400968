import BaseExtension from '@/views/BaseExtension'
export default BaseExtension.extend({
    props: {
        startCollapsed: {
            type: Boolean,
            default: false
        },
        buttonTop: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        fixed: {
            type: Boolean,
            default: false
        },
        borderless: {
            type: Boolean,
            default: false
        },
        callback: {
            type: Function
        }
    },

    data() {
        return {
			visible: false,
        }
    },
	watch: {
		startCollapsed: {
			immediate: true,
			handler(val) {
				this.visible = !val
			}
		}
	}
})