<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Treatments
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <prescriptions v-if="checkUserPermission('prescription', 'can_view')"></prescriptions>
        <chronic-prescriptions v-if="checkUserPermission('prescription', 'can_view')"></chronic-prescriptions>
        <vaccinations v-if="checkUserPermission('vaccination', 'can_view')"></vaccinations>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import Prescriptions from "./treatments/Prescriptions"
import ChronicPrescriptions from "./treatments/ChronicPrescriptions"
import Vaccinations from "./treatments/Vaccinations"

export default TabExtension.extend({
    name: 'health',
    components: {
        'prescriptions': Prescriptions,
        'chronic-prescriptions': ChronicPrescriptions,
        'vaccinations': Vaccinations,
    }
})
</script>