<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
	<template slot="title">
		<i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> {{ title }}
	</template>
	<validation-observer ref="observer" v-slot="{ passes }">
	<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
        :show="$store.getters['loading/isLoading']('insurance')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
		<b-form-group :disabled="isLocked">
			<b-row>
				<b-col cols="12" md="6">
                    <validation-provider vid="number" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-input id="number" name="number" placeholder="* Number" v-model="form.number"/>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="effective_date" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                		<b-form-input id="effective_date" placeholder="Effective date (DD-MM-YYYY)" v-model="form.effective_date" v-dateformat autocomplete="off"></b-form-input>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="expiry_date" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                		<b-form-input id="expiry_date" placeholder="* Expiry date (DD-MM-YYYY)" v-model="form.expiry_date" v-dateformat autocomplete="off"></b-form-input>
	                </b-form-group>
	                </validation-provider>
				</b-col>
				<b-col cols="12" md="6">
					<validation-provider vid="company_id" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-select id="company_id" name="company_id" plain v-model="form.company_id" @change="onCompanyChange">
	                        <b-form-select-option :value="null">* Select insurance company</b-form-select-option>
	                        <b-form-select-option v-for="company in insurance_companies" :key="company.id" :value="company.id">{{ company.name }}
	                        </b-form-select-option>
	                    </b-form-select>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="type_id" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
	                        <b-form-select-option :value="null">* Select insurance type</b-form-select-option>
	                        <b-form-select-option v-for="type in filteredTypes" :key="type.id" :value="type.id">{{ type.name }}
	                        </b-form-select-option>
	                    </b-form-select>
	                </b-form-group>
	                </validation-provider>
				</b-col>
			</b-row>
		</b-form-group>
	</b-overlay>
    <template v-if="checkUserPermission('insurance', 'can_change')">
        <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
        <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
    </template>
	</b-form>
	</validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
	name: "primary-insurance",
    props: {
        is_primary: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        insurance: {
            type: Object,
        }
    },

	data() {
		return {
			form: new Form({
                is_primary: this.is_primary,
				is_active: false,
				company_id: null,
				type_id: null,
				number: "",
                effective_date: null,
				expiry_date: null,
				consistency_token: null
			})
		}
	},
	computed: {
		...mapState("catalogue", {
            insurance_companies: (state) => state.insurance_companies,
            insurance_types: (state) => state.insurance_types
		}),
		filteredTypes() {
		    if (!this.form.company_id) {
		        return []
		    }

		    const company = this.insurance_companies.find(company => company.id === this.form.company_id)
		    if (!company) {
		        return []
		    }

		    const types = this.insurance_types.filter(item => item.parent === company.code)
		    return types.length > 0 ? types : this.insurance_types.filter(item => item.parent === null)
		}
	},
	watch: {
		insurance: {
			immediate: true,
			handler(val) {
                if (val)
                    this.form.withData(val)
			}
		},
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
	},
	methods: {
        ...mapActions("catalogue", ["getCatalogueItem", ]),
		...mapActions("insurances", ["createPatientInsurance", "updatePatientInsurance"]),
		onCompanyChange() {
			this.form.populate({type_id: null})
		},
		create() {
			this.createPatientInsurance(this.form.data()).then(() => {
                this.reset()
				this.notify(`${title} insurance created`, `${title} insurance was created successfully`)
			})
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer?.setErrors(errors.response.data)
	                this.hasErrors = true
	            }
			})
		},
		async update() {
			this.updatePatientInsurance({id: this.insurance?.id, insurance: this.form.data() }).then(() => {
                this.reset()
				this.notify(`${title} insurance updated`, `${title} insurance was updated successfully`)
			})
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer?.setErrors(errors.response.data)
	                this.hasErrors = true
	            }
			})
		},
		onFormSubmit() {
			if (this.insurance) {
				this.update()
			}else{
				this.create()
			}
		},
        reset() {
            this.form.reset()

            this.$nextTick(() => {
            	this.$refs.observer?.reset()
                this.hasErrors = false
                this.validated()

            })
        }
    },
    async mounted() {
        // Insurances
        await this.getCatalogueItem('insurance_companies')
        await this.getCatalogueItem('insurance_types')
    }
})
</script>