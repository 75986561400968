<template>
    <b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
        <template slot="title">
            <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Security
        </template>
        <b-form-group
            label="Change Password"
            label-size="lg"
            label-class="font-weight-bold pt-0">
            <b-row>
                <b-col>
                    <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onChange)" novalidate>
                            <b-overlay 
                                :show="$store.getters['loading/isLoading']('auth')"
                                rounded="sm"
                                variant="light"
                                :opacity="0.80"
                                blur="2px">
                                <validation-provider vid="non_field_errors" v-slot="{ errors }">
                                    <b-form-group
                                        :disabled="isLocked"
                                        :invalid-feedback="errors[0]"
                                        :state="errors[0] ? false : null">
                                        <validation-provider name="New password" vid="password" v-slot="{ errors }">
                                            <b-form-group
                                                :invalid-feedback="errors[0]"
                                                :state="errors[0] ? false : null">
                                                <b-input-group>
                                                    <b-input-group-prepend>
                                                        <b-input-group-text>
                                                            <i class="fa-solid fa-lock"></i>
                                                        </b-input-group-text>
                                                    </b-input-group-prepend>
                                                    <b-form-input v-if="showPassword" type="text" class="form-control" name="password" v-model="form.password" placeholder="* New password" autocomplete="new-password" tabindex="1" />
                                                    <b-form-input v-else type="password" class="form-control" name="password" v-model="form.password" placeholder="* New password" autocomplete="new-password" tabindex="1" />
                                                    <b-input-group-append>
                                                        <b-button variant="light" @click="showPassword = !showPassword">
                                                            <i class="fa-solid" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider name="New password" vid="password_confirm" rules="confirmed:password" v-slot="{ errors }">
                                            <b-form-group
                                                :state="errors[0] ? false : null">
                                                <b-input-group>
                                                    <b-input-group-prepend>
                                                        <b-input-group-text>
                                                            <i class="fa-solid fa-lock"></i>
                                                        </b-input-group-text>
                                                    </b-input-group-prepend>
                                                    <b-form-input v-if="showPassword" type="text" class="form-control" name="password_confirm" v-model="form.password_confirm" placeholder="* Confirm new password" autocomplete="new-password" tabindex="2" />
                                                    <b-form-input v-else type="password" class="form-control" name="password_confirm" v-model="form.password_confirm" placeholder="* Confirm new password" autocomplete="new-password" tabindex="2" />
                                                    <b-input-group-append>
                                                        <b-button variant="light" @click="showPassword = !showPassword">
                                                            <i class="fa-solid" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <b-form-invalid-feedback :state="errors[0] ? false : null">
                                                    <ul class="list-unstyled">
                                                        <li v-for="error in errors">{{error}}</li>
                                                    </ul>
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                                    <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
                                    <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
                                    <b-button v-show="isDirty | hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                                </validation-provider>
                            </b-overlay>
                        </b-form>
                    </validation-observer>
                </b-col>
                <b-col cols="auto">
                    <b-list-group>
                        <b-list-group-item class="d-flex align-items-center">
                            <b-avatar variant="warning" size="16px" icon="star-fill" class="mr-3"></b-avatar>
                            <span class="mr-auto">Your password can’t be too similar to your other personal information.</span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex align-items-center">
                            <b-avatar variant="warning" size="16px" icon="star-fill" class="mr-3"></b-avatar>
                            <span class="mr-auto">Your password must contain at least 8 characters.</span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex align-items-center">
                            <b-avatar variant="warning" size="16px" icon="star-fill" class="mr-3"></b-avatar>
                            <span class="mr-auto">Your password can’t be a commonly used password.</span>
                        </b-list-group-item>
                        <b-list-group-item class="d-flex align-items-center">
                            <b-avatar variant="warning" size="16px" icon="star-fill" class="mr-3"></b-avatar>
                            <span class="mr-auto">Your password can’t be entirely numeric.</span>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-form-group>
    </b-tab>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: 'user-change-password',
    data() {
        return {
            form: new Form({
                password: "",
                password_confirm: "",
            }),

            showPassword: false
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        }
    },
    methods: {
        ...mapActions("auth", ["changePasswordWithoutLogout"]),
        onChange() {
            this.changePasswordWithoutLogout(this.form.data()).then(() => {
                this.notify('User password updated', "User password was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        reset() {
            this.form.reset()

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.validated()
            })
        }
    }
})
</script>