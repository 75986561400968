<template>
    <b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-flask-round-potion"></i> <strong>Diagnostic Referrals</strong>
            <div class="card-header-actions">
                <b-link v-show="hasTests" class="card-header-action" @click="showChartModal">
                    <i class="fa-regular fa-chart-line"></i>
                </b-link>
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE DIAGNOSTIC REFERRAL ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('lab')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('lab')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">


                    <template v-slot:cell(identification_date)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? toLocaleDate(data.value) : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(type)="data">
                        <span>{{ data.item.type.name }} ({{ data.item.priority.name }})</span>
                    </template>

                    <template v-slot:cell(diagnostics_tests)="data">

                        <!-- <ReferralDetails :referral="data.item" :items="data.value" /> -->
                        <b-list-group>
                            <ReferralUploadResult :referral="data.item" />

                            <Columns :columns="3">
                                <ReferralItemResultForm v-for="item in data.value" :item="item" :key="item.id"  />
                            </Columns>
                        </b-list-group>


                    </template>

                    <template v-slot:cell(actions)="row">
                           <!-- <b-button v-if="row.item.document" variant="success" @click="openDocument(row.item)"><i class="fa fa-paperclip"></i></b-button> -->
                           <!-- <b-button variant="primary" @click="toggleDetails(row)"><i class="fa fa-ellipsis-h"></i></b-button> -->
                           <!-- <b-button variant="primary" @click="showItem(row.item)"><i class="fa fa-ellipsis-h"></i></b-button> -->
                            
                            <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <template #row-details="row"></template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>

                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="3" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                    <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'" class="mb-3"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                        <b-collapse id="add" v-model="addNew" variant="primary">

                            <ReferralsForm @completed="addNew = false"/>

                        </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import Vue from 'vue'
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import ReferralsForm from './ReferralsForm'
import ReferralUploadResult from './ReferralUploadResult'
import ReferralItemResultForm from './ReferralItemResultForm'
import ChartModal from './ChartModal'
import { Columns } from 'vue-columns'
import _ from 'lodash'

export default ComponentExtension.extend({
    name: "diagnostic-referrals",
    components: {
        ReferralsForm,
        ReferralUploadResult,
        ReferralItemResultForm,
        Columns
    },
    data() {
        return {
            addNew: false,
            fields: [
                { key: "actions", label: "", class: "" },
                { key: 'created', label: 'Date/Time', class: 'font-weight-bold', formatter: (value) => this.toLocaleDate(value) },
                { key: 'type', label: 'Type', class: 'font-weight-bold' },
                // { key: 'priority.name', label: 'Priority', class: 'font-weight-bold' },
                { key: 'diagnostics_tests', label: 'Tests', class: 'font-weight-bold' },
                
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,


            confirm: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('lab', ['getPatientDiagnostics']),
        items() {
            return _.cloneDeep(this.getPatientDiagnostics()).map(item => {
                return {...item, _showDetails: true}
            })
        },
        hasTests() {
            return this.items.length > 0
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions('lab', ['getDiagnosticsByPatientId', 'getLabChartDataByPatientId', 'deleteDiagnostics']),
        onRemoveItem(item) {
            this.confirm = true
            this.selected = item
        },
        onConfirmed(item) {
            this.deleteDiagnostics(item.id).then(() => {
                this.notify("Patient diagnostics referral removed", "Patient diagnostic referral was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.selected = null
            })
        },
        async showChartModal() {
            var ComponentClass = Vue.extend(ChartModal)

            const chartData = await this.getLabChartDataByPatientId()
            var instance = new ComponentClass({ parent: this, propsData: { items: chartData } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$mount()
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        showItem(item) {
            this.selected = this.selected ? null : item

        },
        openDocument(referral) {
            if(referral.document_extension === ".pdf") {
                window.open(referral.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [referral.document_url],
                })
            }
        },
    },
    async mounted() {
        await this.getDiagnosticsByPatientId()
    }
})
</script>