<template>
    <b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-weight-scale"></i> <strong> Measurements </strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
            <measurements-base borderless :callback="createPatientMeasurements" />
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import MeasurementsBase from './MeasurementsBase'
import { mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "measurements",
    components: {
        'measurements-base': MeasurementsBase
    },
    methods: {
        ...mapActions('health', ['createPatientMeasurements'])
    }
})
</script>