<template>
    <b-card class="card-accent-info" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-folder-medical"></i> <strong> Medical Cards </strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
            <medical-cards-base :patient="patient" />
        </b-collapse>
    </b-card>
</template>
</template>
<script>
import ComponentExtension from "@/core/components/ComponentExtension"
import { mapState } from "vuex"
import MedicalCardsBase from "./MedicalCardsBase"

export default ComponentExtension.extend({
	name: "medical-cards",
	components: {
		'medical-cards-base': MedicalCardsBase
	},
	computed: {
		...mapState("patients", {
			patient: (state) => state.current
		})
	},
})
</script>