import Vue from 'vue'
import VueRouter from 'vue-router'
import { mapActions, mapState, mapGetters } from 'vuex'

export default Vue.extend({
    computed: {
        ...mapGetters('manager', ['hasUnsavedChanges']),
        currentView() {
            return this.$options.name
        },
        canGoBack() {
            // Check if there is a previous route in the Vue Router history stack
            // console.log('length', window.history.length)
            return this.$router.history.current !== VueRouter.START_LOCATION
        }
    },
    methods: {
        ...mapActions('manager', ['componentStateChanged', 'resetComponentStates']),
        dirty() {
          this.componentStateChanged({ id: this.currentView, status: 'dirty' })
        },
        validated() {
          this.componentStateChanged({ id: this.currentView, status: 'validated' })
        },
        resetStates() {
            this.resetComponentStates()
        },
        goBack() {
            // Use Vue Router to go back to the previous route
            this.$router.back()
        },
        notify(title, msg, variant = 'success') {
            EventBus.$emit('showToast', { title: title, message: msg, variant: variant})
        }
    }
})