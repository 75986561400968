<template>
<b-card>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

    <validation-provider vid="non_field_errors" v-slot="{ errors }">
      <b-form-invalid-feedback :state="errors[0] ? false : null">
        <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
      </b-form-invalid-feedback>
    </validation-provider>

        <validation-provider name="Height" vid="height" rules="measurement" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Height"
                label-for="height"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                 <b-input-group>
                    <b-form-input type="number" id="height" v-comma2dot name="height" placeholder=".00" v-model="form.height"  />
                    <template #append>
                        <b-form-select id="height_unit" name="height_unit" class="squared-left" plain v-model="form.height_unit">
                            <b-form-select-option :value="null">Height Unit</b-form-select-option>
                            <b-form-select-option value="ft">ft</b-form-select-option>
                            <b-form-select-option value="inch">inch</b-form-select-option>
                            <b-form-select-option value="m">m</b-form-select-option>
                            <b-form-select-option value="cm">cm</b-form-select-option>
                        </b-form-select>
                    </template>
                </b-input-group>
                 <validation-provider vid="height_unit" v-slot="{ errors }">
                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                </validation-provider>
                </b-input-group>
            </b-form-group>
        </validation-provider>
        <validation-provider name="Weight" vid="weight" rules="measurement" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Weight"
                label-for="weight"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-input-group>
                    <b-form-input type="number" id="weight" v-comma2dot name="weight" placeholder=".00" v-model="form.weight" />
                    <template #append>
                        <b-form-select id="weight_unit" name="weight_unit" class="squared-left" plain v-model="form.weight_unit">
                            <b-form-select-option :value="null">Weight Unit</b-form-select-option>
                            <b-form-select-option value="g">g</b-form-select-option>
                            <b-form-select-option value="kg">kg</b-form-select-option>
                            <b-form-select-option value="lb">lb</b-form-select-option>
                        </b-form-select>
                    </template>
                </b-input-group>
                 <validation-provider vid="weight_unit" v-slot="{ errors }">
                    <b-form-invalid-feedback :state="errors[0] ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>
        </validation-provider>
        <validation-provider name="Waist circumference" vid="waist" rules="measurement" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Waist circumference"
                label-for="waist"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-input-group append="cm">
                    <b-form-input type="number" id="waist" v-comma2dot name="waist" placeholder=".00" v-model="form.waist" />
                </b-input-group>
            </b-form-group>
        </validation-provider>
        <validation-provider name="Hip" vid="hip" rules="double:2" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Hip "
                label-for="hip"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-input-group append="cm">
                    <b-form-input type="number" id="hip" v-comma2dot name="hip" placeholder=".00" v-model="form.hip" />
                </b-input-group>
            </b-form-group>
        </validation-provider>
        <validation-provider name="Glucose" vid="glucose" rules="measurement" v-slot="{ errors }">
            <b-form-group
                :label-size="small ? 'sm' : 'md'"
                label-cols-sm="2"
                label-cols-lg="3"
                label="Glucose"
                label-for="glucose"
                :invalid-feedback="errors[0]"
                :state="errors[0] ? false : null">
                <b-input-group append="mmol/L">
                    <b-form-input type="number" id="glucose" v-comma2dot name="glucose" placeholder=".00" v-model="form.glucose" />
                </b-input-group>
            </b-form-group>
        </validation-provider>

        <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

    </b-form>
    </validation-observer>
</b-card>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

export default FormExtension.extend({
    name: "measurements-form",
    data() {
        return {
            form: null
        }
    },
    computed: {
        ...mapState('catalogue', {
            body_weight_measurement_methods: state => state.body_weight_measurement_methods,
            body_height_measurement_methods: state => state.body_height_measurement_methods
        })
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        refresh: {
            immediate: true,
            handler(val) {
                if(val) {
                   this.reset()
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        onFormSubmit() {
            this.callback(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient measurements created", "Patient measurements was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                height: "",
                height_unit: defaults.body_height_unit,
                weight: "",
                weight_unit: defaults.body_weight_unit,
                waist: "",
                hip: "",
                glucose: "",
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.hasErrors = false
                this.validated()
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('body_weight_measurement_methods')
        await this.getCatalogueItem('body_height_measurement_methods')
    }
})
</script>