<template>
    <div class="referral-tests p-3 mb-3">
        <template v-if="hasfilteredItems">
            <Columns :columns="2">
                <b-form-group
                    v-for="group in items"
                    :key="group.id"
                    :label="group.parent_name"
                    label-size="lg"
                    label-class="font-weight-bold"
                >
                <b-form-checkbox-group
                    v-model="selected"
                    :options="group.items"
                    text-field="name"
                    value-field="id"
                    stacked
                    switches
                    @change="onChange"
                ></b-form-checkbox-group>
                </b-form-group>
            </Columns>
        </template>
        <template v-else>
            <div class="text-muted text-center my-2">
                <span>No items found.</span>
            </div>
        </template>
    </div>
</template>
<script>
import { Columns } from 'vue-columns'

export default {
    name: "diagnostic-referral-tests",
    props: ['value', 'items'],
    components: {
        Columns
    },
    data() {
        return {
            selected: []
        }
    },
    computed: {
        hasfilteredItems() {
            return Object.values(this.items).some(group => group.items && group.items.length > 0);
        },
    },
    watch: {
        value(val) {
            this.selected = Array.from(val)
        }
    },
    methods: {
        onChange(val) {
            this.$emit('input', val)
            this.$emit('change', val)
        }
    },
    created() {
        this.selected = Array.from(this.value)
    }
}
</script>
<style>
/* Optional: Add styles for better appearance */
.referral-tests {
    height: 320px;
    overflow-y: scroll;
    border: 1px solid #c8ced3; /* Highlight scrollable area */
    border-radius: 0.25rem;
}
</style>