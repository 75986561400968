<template>
<b-row>
    <b-col>
	<b-card>
	    <validation-observer ref="observer" v-slot="{ passes }">
	    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
	        <validation-provider vid="priority_id" v-slot="{ errors }">
	        <b-form-group
	            :invalid-feedback="errors[0]"
	            :state="errors[0] ? false : null">
	             <b-input-group prepend="Priority">
	            <b-form-select id="priority_id" name="priority_id" plain v-model="form.priority_id">
	                <b-form-select-option disabled :value="null">* Select Referral Priority</b-form-select-option>
	                <b-form-select-option v-for="priority in referral_priorities" :key="priority.id" :value="priority.id">{{ priority.name }}
	                </b-form-select-option>
	            </b-form-select>
	            </b-input-group>
	        </b-form-group>
	        </validation-provider>
	        <validation-provider vid="type_id" v-slot="{ errors }">
	        <b-form-group
	            :invalid-feedback="errors[0]"
	            :state="errors[0] ? false : null">
	            <b-input-group prepend="Type">
	                <b-input-group-prepend>
	                    <b-form-select class="squared-left squared-right" id="type_id" name="type_id" plain v-model="form.type_id" @change="onReferralTypeSelected">
	                        <b-form-select-option disabled :value="null">* Select Referral Type</b-form-select-option>
	                        <b-form-select-option v-for="type in filteredTypes" :key="type.id" :value="type.id">{{ type.name }}
	                        </b-form-select-option>
	                    </b-form-select>
	                </b-input-group-prepend>
	                <b-form-input placeholder="Filter tests..." v-model="filterQuery"/>
	                <b-input-group-prepend>
	                    <b-button variant="warning" @click="filterQuery = ''"><i class="fa-regular fa-arrows-rotate-reverse"></i> Clear</b-button>
	                </b-input-group-prepend>
	            </b-input-group>
	        </b-form-group>
	        </validation-provider>

	        <b-form-group>
	            <ReferralTests v-model="form.tests" :items="filteredItems"/>
	        </b-form-group>

	        <b-button :disabled="!hasReferrals" class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
	        <b-button v-if="isDirty || hasErrors" class="mr-1" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
	    </b-form>
	    </validation-observer>
	    </b-card>
        </b-col>
        <b-col cols="4">
        	<TemplateSaved/>
        	<TemplateForm v-model="form.tests" :type-id.sync="form.type_id" @change="onReferralTypeSelected"/>
        </b-col>
    </b-row>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import ReferralTests from './ReferralTests'
import TemplateForm from './TemplateForm'
import TemplateSaved from './TemplateSaved'

export default FormExtension.extend({
	name: 'referral',
    components: {
        ReferralTests,
        TemplateForm,
        TemplateSaved
    },
    data() {
        return {
            form: null,

            filterQuery: "",
            filteredItems: [],

            selectedTemplate: null
        }
    },
    computed: {
        filteredTypes() {
            return this.referral_types.filter(type => ['Laboratory', 'Radiology'].includes(type.name))
        },
        ...mapState('catalogue', {
            referral_types: state => state.referral_types,
            referral_priorities: state => state.referral_priorities,
        }),
        hasReferrals() {
            return this.form.tests.length > 0
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()

                if (this.isDirty) {
                    this.dirty()
                }else{
                    this.validated()
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        filterQuery: {
            handler(val) {
                this.filterItems()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('lab', ['filterDiagnosticsTests', 'createDiagnostics',]),
        async filterItems() {
            const referralType = this.referral_types.find(type => type.id === this.form.type_id)
            const parentName = referralType ? referralType.name : "Laboratory"

            const data = await this.filterDiagnosticsTests({ parent: parentName, search: this.filterQuery })

            const groupedItems = new Map()

            data.forEach(item => {
                const { parent_id: parentId, parent_name: parentName } = item

                if (!groupedItems.has(parentId)) {
                    groupedItems.set(parentId, { parent_name: parentName, items: [] })
                }
                groupedItems.get(parentId).items.push(item)
            })

            // Convert back to an object if needed
            this.filteredItems = Object.fromEntries(groupedItems)
        },
        async onReferralTypeSelected(id) {
            this.filterQuery = ""
            await this.filterItems()
        },
        // updateForm(data) {
        //     const form_data = this.form.only(['priority_id'])
        //     if(data) {
        //         this.form.withData({
        //             type_id: data.type_id,
        //             priority_id: form_data.priority_id,
        //             tests: data.tests,
        //             consistency_token: null
        //         })
        //     }else{
        //         this.reset()
        //     }
        // },
        onFormSubmit() {            
            this.createDiagnostics(this.form.data()).then(() => {
                this.reset()
                this.notify("Lab referral created", "Lab referral was created successfully")
                this.$emit('created')
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer?.setErrors(errors.response.data)
                    this.hasErrors = false
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                type_id: defaults.referral_type,
                priority_id: defaults.referral_priority,
                tests: [],
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)

            this.$nextTick(() => {
                this.$refs.observer?.reset()
                this.filterQuery = ""
                this.hasErrors = false
                this.validated()
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('referral_types')
        await this.getCatalogueItem('referral_priorities')
        await this.filterItems()
    },
})
</script>
<style>
/* Optional: Add styles for better appearance */
.referral-tests {
    height: 320px;
    overflow-y: scroll;
    border: 1px solid #c8ced3; /* Highlight scrollable area */
    border-radius: 0.25rem;
}
</style>