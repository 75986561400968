import ComponentExtension from './ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
    props: {
        refresh: {
            type: Boolean,
            default: false
        },
        callback: {
            type: Function
        },
        small: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isLocked: true,
            isDirty: false,
            hasErrors: false,
        }
    },
    computed: {
        ...mapState('catalogue', {
            catalogue_defaults: state => state.catalogue_defaults
        })
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueDefaults']),
    },
    async mounted() {
        await this.getCatalogueDefaults()

    }
})