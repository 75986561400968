<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Insurances
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <primary-insurance></primary-insurance>
        <secondary-insurance></secondary-insurance>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import PrimaryInsurance from "./insurances/PrimaryInsurance"
import SecondaryInsurance from "./insurances/SecondaryInsurance"

export default TabExtension.extend({
    name: 'insurances',
    components: {
        'primary-insurance': PrimaryInsurance,
        'secondary-insurance': SecondaryInsurance
    }
})
</script>