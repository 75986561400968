<template>
    <b-list-group-item class="flex-column align-items-start">
    <div class="d-flex w-100 justify-content-between">
        <div class="align-middle">
            <i class="mr-2 fa-solid fa-microscope"></i> {{test?.parent_name}}: {{test?.name}}
        </div>
        <div class="d-flex">
            <b-link class="ml-5" @click="onRemove">
                <i class="fa-solid fa-xmark align-middle"></i>
            </b-link>
        </div>
    </div>
    </b-list-group-item>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
    name: "lab-template-test-item",
    props: ['test'],
    methods: {
        onRemove() {
            this.$emit('remove', this.test?.id)
        }
    }
}
</script>