<template>
    <!-- <b-list-group-item :active="isActive" href="#" @dblclick="updateItem = true" @click="onSelected" class="flex-column align-items-start"> -->
    <b-list-group-item :active="confirm ? null : isActive" href="#" @click="onSelected" class="flex-column align-items-start">
<!--         <template v-if="updateItem">
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form inline @submit.prevent="passes(onFormSubmit)" novalidate>
                <b-button size="sm" class="mr-1" variant="warning" @click="updateItem = false">Undo</b-button>
                <validation-provider vid="name" v-slot="{ errors }">
                    <b-form-input size="sm" :state="errors[0] ? false : null" id="name" name="name" v-model="form.name" placeholder="Set name..." />
                </validation-provider>
                <b-button size="sm" type="submit" class="ml-1" variant="success">Update</b-button>
            </b-form>
            </validation-observer>
        </template> -->
        <template v-if="confirm">
            <div class="d-flex w-100 justify-content-between">
                <div class="align-middle">
                    <i class="mr-2 fa-solid fa-star"></i> Delete {{ item?.name }}?
                </div>
                <div class="d-flex">
                    <b-button size="sm" variant="danger" class="mr-1" @click="onCancel"><i class="fa-light fa-xmark"></i></b-button>
                    <b-button size="sm" variant="success" @click="onConfirmed"><i class="fa-light fa-check"></i></b-button>
                </div>
            </div>
        </template>
        <div v-else class="d-flex w-100 justify-content-between">
            <div class="align-middle">
                <i class="mr-2 fa-solid fa-star"></i> {{ item?.name }}
            </div>
            <div class="d-flex">
<!--                 <b-link @click.stop="onEdit" :class="{'text-light' : isActive}">
                    <i class="fa-solid fa-pen align-middle"></i>
                </b-link> -->
                <b-button size="sm" variant="link" class="ml-3" @click="onRemoveItem" :class="{'text-light' : isActive}">
                    <i class="fa-solid fa-xmark align-middle"></i>
                </b-button>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
	name: "lab-template-item",
	props: {
        item: {
          type: Object,
          required: true
        },
        isActive: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
        	updateItem: false,
            form: new Form({
                name: "",
                consistency_token: null
            }),

            confirm: false
        }
	},
    watch: {
        item: {
            immediate: true,
            handler(val) {
                if(val) {
                    this.form.withData(val)
                }
            }
        },
        updateItem(val) {
            if (!val) {
                this.form.reset()
            }
        }
    },
	methods: {
        onRemoveItem() {
            this.confirm = true
        },
        onConfirmed() {
            this.$emit('deleted', this.item?.id)
            this.confirm = false
        },
        onCancel() {
            this.confirm = false
        },
        onSelected() {
            if(!this.confirm) {
                this.$emit('selected', this.item, this.item?.id)
            }
        }
	}
}
</script>