import Vue from 'vue'

const state = {
	componentStates: {} 
}

// getters
const getters = {
    hasUnsavedChanges: (state) => () => {
      return Object.values(state.componentStates).some((status) => status === 'dirty')
    }
    // hasUnsavedChanges: (state) => () => {
    //   const parentStates = state.componentStates[parent] || {}
    //   console.log('parentStates', parentStates)
    //   return Object.values(parentStates).some((status) => status === 'dirty')
    // },
}

// actions
const actions = {
	componentStateChanged({ commit }, { id, status }) {
		commit("set_component_state", { id, status })
	},
	resetComponentStates({ commit }) {
		commit("reset_states")
	},
}

// mutations
const mutations = {
    // set_component_state(state, { id, status }) {
    //   if (!state.componentStates[parent]) {
    //     Vue.set(state.componentStates, parent, {}) // Initialize parent if not present
    //   }
    //   Vue.set(state.componentStates[parent], id, status); // Set child state
    // },
    // reset_parent_states(state, parent) {
    //   if (state.componentStates[parent]) {
    //     Vue.set(state.componentStates, parent, {}); // Reset all states for a parent
    //   }
    // },
    // reset_all_states(state) {
    //   state.componentStates = {}; // Clear all states
    // }

    set_component_state(state, { id, status }) {
      Vue.set(state.componentStates, id, status) // Ensure reactivity
    },
    reset_states(state) {
      state.componentStates = {} // Clear all states
    }

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}