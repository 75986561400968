<template>
    <div>
        <div v-if="buttonTop" class="mb-3">
            <b-button v-if="checkUserPermission('vaccination', 'can_add')" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
            <b-collapse id="add" v-model="addNew" variant="primary">
                <VaccinationsForm class="mt-3" :callback="callback" :small="small" @created="addNew = false" :refresh="addNew" />
            </b-collapse>
        </div>
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE VACCINATION ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('vaccinations')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    striped
                    hover
                    :fixed="fixed"
                    :small="small"
                    :borderless="borderless"
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('vaccinations')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(ended)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? toLocaleDate(data.value) : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>
                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="6" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer v-if="!buttonTop">
                    <b-button v-if="checkUserPermission('vaccination', 'can_add')" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                    <b-collapse id="add" v-model="addNew" variant="primary">
                        <VaccinationsForm class="mt-3" :callback="callback" :small="small" @created="addNew = false" :refresh="addNew"/>
                    </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
</div>
</template>
<script>
import ComponentBaseExtension from "@/core/components/ComponentBaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import VaccinationsForm from './VaccinationsForm'

export default ComponentBaseExtension.extend({
    name: "vaccinations-base",
    components: {
        VaccinationsForm
    },
    data() {
        return {
            addNew: false,
            fields: [
                { key: 'start', label: 'Start Date', class: {"align-middle" : true, "small": this.small }, formatter: (value) => this.toLocaleDate(value) },
                { key: 'vaccine.name', label: 'Vaccine', class: {"align-middle" : true, "text-left": true, "small": this.small } },
                { key: 'dosage_form.name', label: 'Dosage form', class: {"align-middle" : true, "text-left": true, "small": this.small } },
                { key: 'route_full', label: 'Route', class: {"align-middle" : true, "text-center": true, "small": this.small } },
                { key: 'amount_full', label: 'Administered', class: {"align-middle" : true, "text-center": true, "small": this.small } },
                { key: 'administered_site.name', label: 'Administered Site', class: {"align-middle" : true, "text-left": true, "small": this.small } },
                { key: 'end', label: 'End Date', class: {"align-middle" : true, "text-center": true, "small": this.small }, formatter: (value) => this.toLocaleDate(value) },
                
                { key: "actions", label: "", class: {"align-middle" : true, "text-center": true, "small": this.small } },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,
            
            confirm: false,
            processing: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('vaccinations', ['getPatientVaccinations']),
        items() {
            return this.getPatientVaccinations()
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        },

    },
    methods: {
        ...mapActions('vaccinations', ['getVaccinationsByPatientId', 'deleteVaccination']),
        
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {
            this.processing = true
            this.deleteVaccination(item.id).then(() => {
                this.notify("Patient vaccination removed", "Patient vaccination was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.processing = false
                    this.selected = null
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        }
    },
    async mounted() {
        await this.getVaccinationsByPatientId()

    }
})
</script>