<template>
<b-tab title-item-class="mb-1" class="p-0" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Health
    </template>
    <b-tabs pills card content-class="border-left-0 border-right-0 border-bottom-0">
        <blood-type v-if="checkUserPermission('bloodtype', 'can_view')"></blood-type>
        <vital-functions v-if="checkUserPermission('vitals', 'can_view')"></vital-functions>
        <measurements v-if="checkUserPermission('measurements', 'can_view')"></measurements>
        <attests v-if="checkUserPermission('attest', 'can_view')"></attests>
        <allergies v-if="checkUserPermission('allergies', 'can_view')"></allergies>
    </b-tabs>
</b-tab>
</template>
<script>
import TabExtension from './TabExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import BloodType from "./health/BloodType"
import VitalFunctions from "./health/VitalFunctions"
import Measurements from "./health/Measurements"
import Attests from "./health/Attests"
import Allergies from "./health/Allergies"

export default TabExtension.extend({
    name: 'health',
    components: {
        'blood-type': BloodType,
        'vital-functions': VitalFunctions,
        'measurements': Measurements,
        'attests': Attests,
        'allergies': Allergies
    }
})
</script>