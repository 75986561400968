<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Prescriptions
    </template>
    <prescriptions-base button-top small fixed :callback="createPatientAdminPrescription" />
</b-tab>
</template>
<script>
import TabExtension from "../TabExtension"
import PrescriptionsBase from '@/core/components/prescriptions/PrescriptionsBase'
import { mapActions } from "vuex"

export default TabExtension.extend({
    name: "prescriptions",
    components: {
        'prescriptions-base': PrescriptionsBase
    },
    methods: {
        ...mapActions('prescriptions', ['createPatientAdminPrescription']),
    }
})
</script>