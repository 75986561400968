<template>
    <b-card class="card-accent-dark" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-envelope"></i> <strong>Letters</strong>
            <div class="card-header-actions">
                <b-button size="sm" type="button" variant="primary" @click="showBuilder = true"><i class="fa-solid fa-envelope-open-text"></i> Open Template Builder</b-button>
            </div>
        </template>
        <b-collapse v-model="visible">
            <b-overlay :show="confirm" no-wrap @shown="onShown">
                <template #overlay>
                    <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                        <p class="text-center">
                            <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE LETTER ON {{ toLocaleDate(selected?.created) }} </strong>
                        </p>
                        <div class="text-center">
                            <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                            <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>
            <b-overlay 
                :show="$store.getters['loading/isLoading']('letters')"
                rounded="sm"
                variant="light"
                :opacity="0.80"
                blur="2px">
                <b-card-body class="p-0">
                    <b-table
                        ref="table"
                        class="m-0"
                        responsive
                        stacked="sm"
                        show-empty
                        borderless
                        striped
                        hover
                        primary-key="id"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :items="letters"
                        :fields="fields"
                        :busy.sync="$store.getters['loading/isLoading']('letters')"
                        :no-sort-reset="true"
                        :sort-by.sync="sortedBy"
                        :sort-desc.sync="sortedDesc"
                        :no-border-collapse="false">

                        <template v-slot:cell(actions)="row">
                            <b-button-group size="sm">
                                <b-button variant="secondary" v-b-tooltip.hover title="Print letter" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
                                <b-button variant="danger" v-b-tooltip.hover title="Export to PDF" @click="exportRow(row, 'pdf')"><i class="fa-solid fa-file-pdf"></i></b-button>
                                <b-button variant="primary" v-b-tooltip.hover title="Export to Word" @click="exportRow(row, 'docx')"><i class="fa-solid fa-file-word"></i></b-button>
                                <b-button variant="warning" v-b-tooltip.hover title="Edit letter" @click="onEditItem(row)"><i class="fa-solid fa-pen"></i></b-button>
                                <b-button variant="danger" @click="onRemoveItem(row)" v-b-tooltip.hover title="Remove letter"><i class="fa-light fa-xmark"></i></b-button>
                            </b-button-group>
                        </template>

                        <div slot="table-busy" class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                        <template v-slot:empty="scope">
                            <div class="small text-muted text-center my-2">
                                <span>n/a</span>
                            </div>
                        </template>
                        <template v-slot:custom-foot="scope">
                            <b-tr class="border-top">
                                <b-td colspan="3" class="align-middle" variant="light" style="background: #fff;">
                                    Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                                </b-td>
                                <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                    <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                                </b-td>
                            </b-tr>
                        </template>
                    </b-table>
                    <b-card-footer>
                        <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                        <b-collapse id="add" v-model="addNew" variant="primary">
                            <b-card class="mt-3" no-body>
                                <ck-editor :disabled="!this.editorData" v-model="editorData" :config="editorConfig"/>
                            </b-card>
                        </b-collapse>
                    </b-card-footer>
                </b-card-body>
            </b-overlay>
        </b-collapse>
        <TemplateBuilder v-if="showBuilder" @close="showBuilder = false" />
    </b-card>
</template>
<script>
import Vue from 'vue'

import LetterExtension from './LetterExtension'
import { mapState, mapGetters, mapActions } from "vuex"

import TemplateBuilder from './TemplateBuilder'
import FileManagerModal from './FileManagerModal'
import SaveAsModal from './SaveAsModal'

import printJS from 'print-js'

export default LetterExtension.extend({
    name: "letters",
    components: {
        TemplateBuilder
    },
    data() {
        return {
            addNew: false,

            form: new Form({
                name: null,
                edited_content: '',
                template: null,
                consistency_token: null
            }),

            editorData: '',
            editorConfig: {
                toolbar: {
                    removeItems: [ 'placeholder' ]
                },
                menuBar: {
                    removeItems: [ 'menuBar:new', 'menuBar:sourceEditing', 'menuBar:pdf', 'menuBar:word', 'menuBar:print', 'menuBar:preview', ]
                },
                fileMenu: {
                    open: this.openFile,
                    saveAs: this.saveAs,
                    save: this.onSave,
                },
                autosave: {
                    // waitingTime: 5000, // in ms
                    save: this.autosave
                }
            },

            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle' , formatter: (value) => this.toLocaleDate(value) },
                { key: 'name', label: 'Name', class: 'align-middle' },
                { key: 'updated', label: 'Updated', class: 'text-center align-middle' , formatter: (value) => value ? this.toLocaleDate(value) : 'n/a' },

                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            confirm: false,
            processing: false,
            selected: null,

            isDisabled: true,
            showBuilder: false
        }
    },
    computed: {
        ...mapState('letters', {
            current: state => state.current.letter
        }),
        ...mapGetters('letters', ['getTemplates', 'getPatientLetters']),
        templates() {
            return this.getTemplates()
        },
        letters() {
            return this.getPatientLetters()
        },
        totalRows() {
            return this.letters.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        addNew(val) {
            if (!val) {
                this.reset()
            }
        },
        editorData( val ) {
            if (val) {
                this.isDisabled = false
            }
        }
    },
    methods: {
        ...mapActions('letters', ['setCurrentLetter', 'getLetterTemplates', 'getLettersByPatientId', 'createPatientLetter', 'updatePatientLetter', 'deletePatientLetter', 'exportToById', 'templateToHTMLById', 'letterToHTMLById', 'letterToHTML']),

        // showBuilder() {
        //     EventBus.$emit('showLetterBuilder')
        // },

        reset() {
            this.form.reset()
            this.editorData = ''
            this.setCurrentLetter(null)
        },

        openFile( editor ) {
            const action = this.createPendingAction(editor, 'waiting...')

            var ComponentClass = Vue.extend(FileManagerModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { items: this.templates, title: 'Templates', showBuilderButton: true }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("selected", (selected) => {
                this.open(selected)
            })

            instance.$on("open-builder", () => {
                this.showBuilder()
            })

           

            instance.$mount()
        },

        async open(selected) {
            this.form.populate({template: selected.id})
            const letter = await this.templateToHTMLById(selected.id)
            this.editorData = letter
        },

        saveAs( editor ) {
            const action = this.createPendingAction(editor, 'saving as...')

            var ComponentClass = Vue.extend(SaveAsModal)
            var instance = new ComponentClass({
                parent: this,
                propsData: { items: this.letters, title: 'Letters' }
            })

            instance.$on("hidden", () => {
                instance.$destroy()
                this.stopPendingAction(editor, action)
            })

            instance.$on("save-new", (name) => {
                this.form.populate({name: name})
                this.save(editor, true)
            })

            instance.$on("saved", () => {
                this.save(editor)
            })

            instance.$mount()
        },

        save( editor, isNew = false ) {
            const action =  this.createPendingAction(editor, 'saving...')

            if (isNew) {
                this.form.populate({edited_content: this.editorData})
                this.createPatientLetter(this.form.data())
                    .then(() => {
                        this.addNew = false
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.stopPendingAction(editor, action)
                    })
            }else{
                this.updatePatientLetter({ edited_content:  this.editorData })
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.stopPendingAction(editor, action)
                    })
            }
        },

        onSave( editor ) {
            if (this.current) {
                this.save( editor )
            }else{
                this.saveAs( editor )
            }
        },

        async edit(item) {
            this.addNew = true
            this.setCurrentLetter(item)
            const letter = await this.letterToHTML()
            this.editorData = letter
        },

        async onEditItem(row) {
            if (!this.current) {
                this.edit(row.item)
                return
            }
            else if (row.item.id === this.current.id) {
                this.edit(row.item)
                return
            }

            const result = await this.$bvModal
                .msgBoxConfirm('Load selected letter? Current changes will be lost!', {
                    title: "Load letter",
                    size: "sm",
                    buttonSize: "lg",
                    okVariant: "danger",
                    okTitle: "Yes",
                    cancelTitle: "Cancel",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })

            if (result) {
                this.edit(row.item)
            }
        },

        onRemoveItem(row) {
            this.confirm = true
            this.processing = false
            this.selected = row.item
        },
        onConfirmed() {
            if (this.selected) {
                this.processing = true
                
                this.deletePatientLetter(this.selected?.id).then(() => {
                    this.notify("Patient letter removed", "Patient letter was removed successfully")
                    this.confirm = false
                    this.processing = false
                    this.selected = null

                })
                .catch(errors => {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.processing = false
                    this.selected = null
                })
            }
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },

        async exportRow(row, type) {
            await this.exportToById({id : row.item.id, type: type})
        },

        async printRow(row) {
            const letter = await this.letterToHTMLById(row.item.id)
            printJS({
                printable: letter,
                type: 'raw-html'
            })
        },

        async autosave( editor ) {
            if (this.current) {
                await this.updatePatientLetter({ edited_content:  this.editorData })
            }
        }
    },
    async mounted() {
        await this.getLettersByPatientId()
        await this.getLetterTemplates()
    },
    async beforeDestroy() {
        this.reset()
    },
})
</script>
