<template>
<div>
	<b-button-toolbar class="mb-3" key-nav aria-label="Template Toolbar">
		<b-button-group class="mx-1">
			<b-button :disabled="noComponents" variant="success" @click="onSave">
				<i class="fa-solid fa-cloud-arrow-up" aria-hidden="true"></i> Save
			</b-button>
			<b-button v-show="isDirty" variant="warning" @click="onUndo">
				<i class="fa-solid fa-rotate-left" aria-hidden="true"></i> Undo
			</b-button>
			<b-button :disabled="doneDisabled" variant="danger" @click="$emit('done')">
				<i class="fa-regular fa-circle-dot" aria-hidden="true"></i> Done
			</b-button>
			<b-button @click="addRow"><i class="fa-regular fa-columns" aria-hidden="true"></i></b-button>
		</b-button-group>
		<b-button class="ml-auto" variant="secondary"><i class="fa-solid fa-circle-info" aria-hidden="true"></i></b-button>
	</b-button-toolbar>
	<vc-builder ref="builder"></vc-builder>
</div>
</template>
<script>
import FormExtension from "@/core/components/FormExtension"
import { mapGetters, mapActions } from "vuex"
import draggable from "vuedraggable"

export default FormExtension.extend({
	name: 'vc-template-builder',
	components: {
		draggable
	},
	props: {
		viewMode: {
			type: String,
			required: true,
		}
	},
    data() {
        return {
            form: new Form({
                view: null,
                components: [],
                consistency_token: null
            })
        }
	},
	computed: {
        ...mapGetters('viewcreator', ['getUserTemplate']),
        userTemplate() {
        	return this.getUserTemplate()
        },
        ...mapGetters('viewcreator', ['getDefaultTemplate']),
        defaultTemplate() {
        	return this.getDefaultTemplate()
        },
        ...mapGetters('viewcreator', ['getRegisteredComponents']),
        registeredComponents() {
        	return this.getRegisteredComponents()
        },
        noComponents() {
        	return this.registeredComponents.length < 1
        },
        doneDisabled() {
        	return this.noComponents || this.isDirty
        }
	},
	watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                if (this.isDirty) {
                    this.dirty()
                }else{
                	this.validated()
                }
            }
        },
		registeredComponents: {
			handler(val) {
				this.form.populate({ components: val })
			}
		}
	},
	methods: {
		...mapActions('viewcreator', ['loadBuilder', 'createUserTemplate', 'updateUserTemplate']),
		loadForm() {
			if (this.viewMode === 'edit') {
				this.form.withData(this.userTemplate)
			}else if (this.viewMode === 'new') {
				this.form.withData({ view: this.defaultTemplate.id, components: [], consistency_token: null })
			}
		},
		onUndo() {
			this.reset()
			this.loadBuilder()
			this.$refs.builder.loadComponents()
		},
		onSave() {
			if(this.viewMode === 'edit') {
				this.update()
			}else if ('new') {
				this.createNew()
			}				
		},
		createNew() {
			this.createUserTemplate(this.form.data()).then(template => {
				this.form.withData(template)
				this.reset()
				this.notify('Template Created', "Template was created successfully")
			})
		},
		update() {						
			this.updateUserTemplate(this.form.data()).then(template => {
				this.form.withData(template)
				this.reset()
				this.notify('Template Updated', "Template was updated successfully")
			})
		},
        reset() {
			this.isDirty = false
			this.validated()
        },
        addRow() {
        	this.$refs.builder.addRow()
        }
	},
	created() {
		this.loadBuilder()
		this.loadForm()
	}
})
</script>